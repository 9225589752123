import React, { useContext, useState, useEffect } from 'react';

import config from '../config';
import { useLazyQuery } from '../hooks/graph';
import { SetupContext } from '../contexts/setup';
import { formControlInvalid } from '../components/forms';
import Spinner from '../components/spinner';
const controller = new AbortController();
let actionSearchTimeout;

const LIMIT = 20; // backend defined "page" size

function MeDashboardActionList() {
	const { providers } = useContext(SetupContext);
	const [ state, setState ] = useState({togglers: {}, actions: [], search: ''});
	const [ getActions, { loading: loadingMore } ] = useLazyQuery('actions', controller);

	// obsoleted: setInterval ( {pollInterval: 600 * 1000}) // 10m
	// https://codesandbox.io/p/sandbox/105x531vkq?file=%2Fsrc%2Findex.js

	const onToggle = id => {
		setState({...state,
			togglers: {...state.togglers, [id]: !state.togglers[id]},
		});
	};

	const onSearch = value => {
		if (state.search === value) {
			return;
		}

		setState({...state, search: value});

		if (state.search.length < 3 && value.length < 3) {
			return;
		}

		actionSearchTimeout && clearTimeout(actionSearchTimeout);

		actionSearchTimeout = setTimeout(() => {
			getActions({variables: {search: value}}).then(({ data }) => setState(s => ({...s,
				actionsLastBatchLength: data.actions.length, // to show/hide the more button
				actions: data.actions,
			})));
		}, 777);
	};

	const onMore = value => {
		getActions({variables: {createdAtFrom: state.actions[state.actions.length - 1].createdAt}}).then(({ data }) => setState(s => ({...s,
			actionsLastBatchLength: data.actions.length, // to show/hide the more button
			actions: state.actions.concat(data.actions),
		})));
	};

	useEffect(() => {
		getActions().then(({ data }) => setState(s => ({...s,
			actionsFirstBatchLength: state.actionsFirstBatchLength ?? data.actions.length, // to show/hide the action list
			actionsLastBatchLength: data.actions.length, // to show/hide the more button
			actions: data.actions,
		})));
	}, []);

	useEffect(() => () => controller.abort(), []); // abort on unmount

	return state && state.actionsFirstBatchLength ? (
		<div className="mb-4">

			{/*
				// temporal, 240228
				// template for temporal notifications (don't remove)
				state.actions.find(item => item.metadata?.includes('misconfiguration')) && (
					<div className="alert alert-warning mt-3" role="alert">
						<div><strong>Interactive Brokers</strong></div>
						There is a partial authentication outage on the broker end. We have contacted their support and they have assured us they are working to fix this as quickly as possible.
					</div>
				)
			*/}

			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col" style={{width: '60%'}}>Last actions</th>
						<th scope="col" className="d-none d-lg-table-cell" style={{width: '20%'}}>Source</th>
						<th scope="col" style={{maxWidth: '20%'}}>Date<span className="d-inline d-lg-none">, Source</span></th>
					</tr>

					<tr>
						<th scope="col" colSpan="3" className="border-0 pt-3">
							<div className="position-relative">
								<input onChange={event => { !loadingMore && onSearch(event.target.value); }} type="text" id="search" placeholder="Search example: AAPL" className={formControlInvalid(state.search.length > 0 && state.search.length < 3, 'form-control border-right-0')} />
								{
									loadingMore && state.search.length >= 3 && <div className="position-absolute top-50 end-0 translate-middle-y"><div className="m-2"><span className="spinner-border spinner-border-sm text-secondary"></span></div></div>
								}
							</div>
						</th>
					</tr>
				</thead>
				{
					state.actions?.length ? (
						<tbody>
							{
								state.actions.map(item =>
									<tr key={item.id}>
										<td>{actionDescription(providers, item, state.togglers[item.id], onToggle)}</td>
										<td className="d-none d-lg-table-cell" style={{maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.address || '-'}</td>
										<td className="text-nowrap">{new Date(Math.round(item.createdAt / 1000) * 1000).toLocaleString()} <div className="d-block d-lg-none" style={{maxWidth: '10em', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.address || '-'} <span className="text-secondary">{config.platform.labels[config.platform.addresses[item.address]] || ''}</span></div></td>
									</tr>
								)
							}
						</tbody>
					) : (
						<tbody>
							<tr>
								<td scope="col" colSpan="3" className="text-center border-0">
									No actions found
								</td>
							</tr>
						</tbody>
					)
				}
			</table>

			{
				state.actionsLastBatchLength >= LIMIT && (
					<div className="mt-3 text-center"><a href="#" onClick={event => { event.preventDefault(); !loadingMore && onMore(); }} role="button" className="btn btn-outline-primary"><Spinner show={loadingMore}>More</Spinner></a></div>
				)
			}
		</div>
	) : false;
}

function actionDescription(providers, action, toggler, onToggle) {
	const metadata = JSON.parse(action.metadata);
	const isMetadata = Object.keys(metadata)?.length > 0;

	return ({
		'Signin': () => `You signed in to the dashboard`,
		'AuthPasswordReset': () => `You reset the password`,
		/* to be dropped */ 'ConnectorCreate': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} connected` : action.code,
		'ConnectorActivate': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} connected` : action.code,
		'ConnectorDelete': () => isMetadata ? `${providers[metadata.providerName]?.title || 'Brokerage'} account(s) ${metadata.accounts?.at(0) ? '#' + metadata.accounts.join(', ') : ''} disconnected` : action.code,
		'HookCreate': () => isMetadata ? `Webhook ${metadata?.shortKey || metadata.key} ${metadata.name ? '(' + metadata.name + ')' : ''} created for ${providers[metadata.providerName]?.title || 'Brokerage'} ${metadata.account ? '#' + metadata.account : ''}` : action.code,
		'HookDelete': () => isMetadata ? `Webhook ${metadata?.shortKey || metadata.key} ${metadata.name ? '(' + metadata.name + ')' : ''} removed from ${providers[metadata.providerName]?.title || 'Brokerage'} ${metadata.account ? '#' + metadata.account : ''}` : action.code,
		'OrderCreate': () => <span onClick={event => { event.preventDefault(); onToggle(action.id); }} role="button">Webhook {metadata.hook?.shortKey} ({providers[metadata.connector?.providerName]?.title || 'Brokerage'}{metadata.hook?.account ? '#' + metadata.hook.account : ''}) {metadata.response?.status == 'rejected' || metadata.response?.error ? 'call failed' : 'called'} <span className={'collapse' + (toggler ? ' show' : '')}><br/> Request: <br/> <code className="alert alert-secondary d-inline-block p-1 mb-0">{JSON.stringify(metadata.request, null, ' ')}</code> <br/> Response: <br/> <code className={`alert alert-${metadata.response?.status?.includes('Error') ? 'danger text-break' : 'success'} d-inline-block p-1 mb-0`}>{JSON.stringify(metadata.response, null, ' ')}</code> { metadata.elapsed && <><br/> Elapsed: <code className="text-body">{metadata.elapsed}ms</code></>} </span></span>
	}[action.code] || (() => '-'))();
}

export default MeDashboardActionList;
